.content-card {
  width: 300px;
  border-radius: 5%;
  height: auto;
  overflow: hidden;
  background-color: lightgray;
}

.content-preview {
  position: relative;
  transform: translateY(-30px);
  clip-path: inset(30px 00px 30px 0px);
}

.content-details {
  margin: 0;
  padding: 0;
  transform: translateY(-60px);
  text-align: left;
  padding: 10px;
}

h4 {
  color: black;
}

p {
  color: black;
  font-weight: 400;
}

p#content-length {
  font-size: 13px;
  font-weight: 300;
  color: black;
}

img {
  clip: rect(0px, 60px, 200px, 0px);
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}

@media (max-width: 600px) {
  .content {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 
ADD MEDIA QUERIES TO RESIZE GRID 
*/
